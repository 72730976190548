
.vertical-stepper {
    display: flex;
    flex-direction: column;
    gap: 20px; /* Adjust spacing between steps */
    position: relative;
  }
  
  .step {
    display: flex;
    flex-direction: column;
    position: relative;
    padding-left: 40px; /* Space for step number */
  }
  
  .step::before {
    content: '';
    position: absolute;
    left: 15px; /* Positioning the line correctly */
    top: 0;
    bottom: 0; /* Extend the line through the entire step */
    width: 2px;
    background-color: var(--primary-color); /* Line color */
  }
  
  .step-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .step-number {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary-color); /* Background color of the number */
    color: white; /* Text color of the number */
    font-weight: bold;
    margin-right: 10px;
  }
  
  .p-panel .p-panel-title {
    font-weight: bold;
  }
  
  .p-field {
    margin-bottom: 1rem;
  }
  
  @media (max-width: 600px) {
    .custom-progress-bar {
        width: 100%;
        margin-top: 10px;
    }
  
    .progress-container {
        flex-direction: column;
    }
  }