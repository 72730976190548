@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@import '~primeicons/primeicons.css';
@layer tailwind-base, primereact, tailwind-utilities;

@layer tailwind-base {
    @tailwind base;
}

@layer tailwind-utilities {
    @tailwind components;
    @tailwind utilities;
}

@layer base {
    body {
        font-family: 'Poppins', sans-serif;
    }

    input,
    select,
    button,
    textarea {
        @apply outline-none;
    }

    .tw-sidebar::-webkit-scrollbar {
        display: none;
    }
}

@layer components {
    .auth-page-section {
        @apply min-h-screen bg-gray-50 text-[#4C4C4C] flex items-center justify-center p-8 md:p-20;
    }

    .popup-box {
        @apply bg-white w-full py-[30px] px-9 space-y-[30px] rounded-lg;
    }

    .input-wrap {
        @apply p-[18px] border border-[#DEDEDE] flex items-center space-x-2 rounded-lg;
    }

    .input-wrap>input,
    select,
    textarea {
        @apply placeholder:text-[#808080];
    }

    .input-label {
        @apply text-sm font-semibold;
    }

    .form-submit-button {
        @apply !w-full !bg-secondary !text-white !p-[18px] !font-semibold !text-sm !rounded-lg;
    }

    .sidebar-nav-link {
        @apply flex items-center space-x-[10px] py-[10px] px-6 border-l-[5px] transition-all hover:text-white hover:border-secondary;
    }

    .dashboard-widget {
        box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.08);
        @apply bg-white shadow-md border border-gray-50 font-semibold rounded-[10px] p-5 space-y-4;
    }

    .tw-modal {
        @apply fixed top-0 left-0 bg-black bg-opacity-40 z-[9999] w-full h-screen items-center justify-center p-4 overflow-y-auto;
    }

    .tw-modals {
        @apply fixed top-0 left-0 bg-opacity-40 z-[9999] w-full h-screen items-center justify-center p-4 overflow-y-auto;
    }

    .tw-modal-content {
        @apply max-w-[850px] w-full bg-white;
    }

    .tw-modal-title {
        @apply flex items-center justify-between px-5 py-3 text-light-gray bg-gray-200 text-lg font-semibold;
    }

    /* .tw-modal-titles {
        @apply flex items-center justify-between px-5 py-3 text-light rgb(28 25 23) text-lg font-semibold;
    } */
    .tw-modal-body {
        @apply px-5 py-3 space-y-[30px];
    }

    .tw-modal-footer {
        @apply flex items-center px-5 pt-3 pb-7 justify-center space-x-8;
    }

    .tw-modal-footer-button {
        @apply w-[180px] p-[18px] rounded-lg text-sm font-semibold;
    }
}

.reverse-mis-modal {
    max-width: 1400px !important;
}

.p-component {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
    font-size: 0.875rem !important;
    font-weight: 400 !important;
}

.p-datatable.p-datatable-gridlines .p-datatable-header {
    border-width: 1px 1px 1px 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-thead>tr>th {
    border-width: 0px 0 1px 1px;
}

.p-tooltip .p-tooltip-text {
    background: none;
    box-shadow: none;
}

.p-tooltip-arrow {
    display: none;
}

.p-dialog-mask {
    z-index: 99999 !important;
}