.flex {
    display: flex;
  }
  
  .flex-column {
    flex-direction: column;
  }
  
  .align-items-center {
    align-items: center;
  }
  
  .mb-2 {
    margin-bottom: 0.5rem;
  }
  
  .w-3rem {
    width: 3rem;
  }
  
  .h-3rem {
    height: 3rem;
  }
  
  .font-bold {
    font-weight: bold;
  }

  .p-organizationchart-line-right, 
.p-organizationchart-line-left, 
.p-organizationchart-line-top ,
.p-organizationchart-lines,
.p-organizationchart-nodes	
	{
  border-color: #000000; /* Connector line color (black) */
}

.p-multiselect-panel{
  z-index: 10000 !important; 
  position: absolute; 
  top: 150px; 
  left: 305.933px; 
  min-width: 810px; 
  transform-origin: center top;
}

.p-multiselect-items-wrapper{
  max-height: 500px !important;
}